import {
  BNB_CONFIRMATIONS,
  ELA_CONFIRMATIONS,
  BNB_ELA_AMB_NATIVE_ERC_MIN_TX,
  BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  AMB_NATIVE_ERC_FEE_FOREIGN,
  AMB_NATIVE_ERC_FEE_HOME,
} from "../config";
import ELA_ICON from "../../../../assets/ela.png";

export const ELA_BNB = {
  0: {
    symbol: "ELA",
    name: "ELA on BSC (Binance)",
    id: "bnbela",
    transferType: "release",
    network: "BSC (Binance)",
    networkID: 56,
    address: "0x76393bb8fd7037962ebdb73f3b30f76dde5cf718",
    confirmations: BNB_CONFIRMATIONS,
    fee: 0,
  },
  1: {
    symbol: "ELA",
    name: "Elastos",
    id: "ela",
    transferType: "mint",
    network: "Elastos",
    networkID: 20,
    address: "",
    confirmations: ELA_CONFIRMATIONS,
    fee: 0.1,
  },
  home: 1,
  foreign: 0,
  icon: ELA_ICON,
  bridgeMode: "amb_native_erc",
  decimals: 18,
  minTx: 200,
  maxTx: BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  priceTicker: "ela",
  priceFeed: "https://api.coincap.io/v2/assets/elastos",
};
