export const ELA_CONFIRMATIONS = 1; // Blocks
export const ELA_AMB_NATIVE_ERC_MIN_TX = 1; // 1000000000000000000
export const ELA_AMB_NATIVE_ERC_MAX_TX = 1500000; // 1500000000000000000000000
export const ELA_AMB_NATIVE_DAILY_LIMIT = 300000000; // 30000000000000000000000000

export const ETH_CONFIRMATIONS = 2; // Blocks
export const ETH_AMB_NATIVE_ERC_MIN_TX = 0.1; // 0.5         // 10000000000000000
export const ETH_AMB_NATIVE_ERC_MAX_TX = 1000000; // 1500000000000000000000000
export const ETH_AMB_NATIVE_DAILY_LIMIT = 300000000; // 30000000000000000000000000

export const HT_CONFIRMATIONS = 2; // Blocks
export const HT_AMB_NATIVE_ERC_MIN_TX = 0.01; // 10000000000000000
export const HT_AMB_NATIVE_ERC_MAX_TX = 1500000; // 1500000000000000000000000
export const HT_AMB_NATIVE_DAILY_LIMIT = 300000000; // 30000000000000000000000000

export const ELA_HT_AMB_NATIVE_ERC_MIN_TX = 0.5;
export const ELA_HT_AMB_NATIVE_ERC_MAX_TX = 40000;
export const ELA_HT_AMB_NATIVE_DAILY_LIMIT = 300000000;

export const BNB_CONFIRMATIONS = 4; // Blocks
export const BNB_HT_AMB_NATIVE_ERC_MIN_TX = 0.0000001;
export const BNB_HT_AMB_NATIVE_ERC_MAX_TX = 1000000;
export const BNB_HT_AMB_NATIVE_DAILY_LIMIT = 300000000;

export const BNB_ELA_AMB_NATIVE_ERC_MIN_TX = 0.01;
export const BNB_ELA_AMB_NATIVE_ERC_MAX_TX = 1500000;
export const BNB_ELA_AMB_NATIVE_DAILY_LIMIT = 300000000;

export const MULTI_AMB_ERC_ERC_MIN_TX = 0.5; // 500000000000000000
export const MULTI_AMB_ERC_ERC_MAX_TX = 750000; // 750000000000000000000000
export const MULTI_AMB_ERC_ERC_DAILY_LIMIT = 15000000; // 15000000000000000000000000

export const MULTI_AMB_ERC_ERC_FEE_HOME = 0; // Percentage
export const MULTI_AMB_ERC_ERC_FEE_FOREIGN = 0; // Percentage
export const AMB_NATIVE_ERC_FEE_HOME = 0; // Percentage
export const AMB_NATIVE_ERC_FEE_FOREIGN = 0.3; // Percentage

// export const AMB_NATIVE_ERC_MIN_TX = 500000000000000000 // Wei
// export const AMB_NATIVE_ERC_MAX_TX = 750000000000000000000000 // Wei
