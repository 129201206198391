import {
  BNB_CONFIRMATIONS,
  ELA_CONFIRMATIONS,
  BNB_ELA_AMB_NATIVE_ERC_MIN_TX,
  BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
} from "../config";
import BNB_ICON from "../../../../assets/bnb.png";

export const BNB_ELA = {
  0: {
    symbol: "BNB",
    name: "Binance Coin",
    id: "bnb",
    transferType: "mint",
    network: "BSC (Binance)",
    networkID: 56,
    address: "",
    confirmations: BNB_CONFIRMATIONS,
    fee: 0,
  },
  1: {
    symbol: "BNB",
    name: "BNB on Elastos",
    id: "elabnb",
    transferType: "release",
    network: "Elastos",
    networkID: 20,
    address: "0x51B85F3889c7EA8f6d5EdEBFBadaDA0fDcE236c9",
    confirmations: ELA_CONFIRMATIONS,
    fee: 0.1,
  },
  home: 0,
  foreign: 1,
  icon: BNB_ICON,
  bridgeMode: "amb_native_erc",
  decimals: 18,
  minTx: 2,
  maxTx: BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  priceTicker: "bnb",
  priceFeed: "https://api.coincap.io/v2/assets/binance-coin",
};
