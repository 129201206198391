import {
  BNB_CONFIRMATIONS,
  ELA_CONFIRMATIONS,
  BNB_ELA_AMB_NATIVE_ERC_MIN_TX,
  BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  MULTI_AMB_ERC_ERC_FEE_FOREIGN,
  MULTI_AMB_ERC_ERC_FEE_HOME,
} from "../config";
import BUSD_ICON from "../../../../assets/busd.png";

export const BUSD_BNB = {
  0: {
    symbol: "BUSD",
    name: "Binance USD",
    id: "busd",
    transferType: "mint",
    network: "BSC (Binance)",
    networkID: 56,
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    confirmations: BNB_CONFIRMATIONS,
    fee: 0,
  },
  1: {
    symbol: "bnbBUSD",
    name: "BUSD on Elastos",
    id: "bnbbusd",
    transferType: "release",
    network: "Elastos",
    networkID: 20,
    address: "0x9f1d0Ed4E041C503BD487E5dc9FC935Ab57F9a57",
    confirmations: ELA_CONFIRMATIONS,
    fee: 0.1,
  },
  home: 0,
  foreign: 1,
  icon: BUSD_ICON,
  bridgeMode: "multi_amb_erc_erc",
  decimals: 18,
  minTx: 1000,
  maxTx: BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  priceTicker: "busd",
  priceFeed: "https://api.coincap.io/v2/assets/binance-usd",
};
