import {
  BNB_CONFIRMATIONS,
  ELA_CONFIRMATIONS,
  BNB_ELA_AMB_NATIVE_ERC_MIN_TX,
  BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  MULTI_AMB_ERC_ERC_FEE_FOREIGN,
  MULTI_AMB_ERC_ERC_FEE_HOME,
} from "../config";
import BTCB_ICON from "../../../../assets/btcb.png";

export const BTCB_BNB = {
  0: {
    symbol: "BTCB",
    name: "Binance BTC",
    id: "btcb",
    transferType: "mint",
    network: "BSC (Binance)",
    networkID: 56,
    address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    confirmations: BNB_CONFIRMATIONS,
    fee: 0,
  },
  1: {
    symbol: "bnbBTCB",
    name: "BTCB on Elastos",
    id: "bnbbtcb",
    transferType: "release",
    network: "Elastos",
    networkID: 20,
    address: "0xDF4191Bfe8FAE019fD6aF9433E8ED6bfC4B90CA1",
    confirmations: ELA_CONFIRMATIONS,
    fee: 0.1,
  },
  home: 0,
  foreign: 1,
  icon: BTCB_ICON,
  bridgeMode: "multi_amb_erc_erc",
  decimals: 18,
  minTx: 0.025,
  maxTx: BNB_ELA_AMB_NATIVE_ERC_MAX_TX,
  priceTicker: "btc",
  priceFeed: "https://api.coincap.io/v2/assets/bitcoin",
};
